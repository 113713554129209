import { memo, useRef, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";

import Message from "./Message";
import { List, CircularProgress } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";

const Messages = () => {
  const d = useAppDispatch();
  const tab = useAppSelector((state) =>
    store.getAppState(state, "roomChatTab")
  );
  const loading = useAppSelector(
    (state) => store.getAppState(state, "roomChatChannelLoading")[tab] ?? false
  );
  const initialized = useAppSelector((state) =>
    store.getAppState(state, "roomChatInitialized")
  );
  const messageIds = useAppSelector(store.getFilteredRoomMessageIds);

  const edge = useRef(true);
  const prevScrollTop = useRef(0);

  const parentRef = useRef<HTMLUListElement>(null);

  const virtualizer = useVirtualizer({
    count: messageIds.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 50,
  });

  const cancelTimer = useRef<number | null>(null);
  const scrollToIndex = useCallback(
    (index: number, align: "start" | "center" | "end") => {
      if (cancelTimer.current) window.clearTimeout(cancelTimer.current);
      virtualizer.scrollToIndex(index, {
        align: align,
      });
      cancelTimer.current = window.setTimeout(() => {
        // @ts-ignore
        virtualizer.cancelScrollToIndex(); // private method
      }, 480);
    },
    [virtualizer, cancelTimer]
  );

  const items = virtualizer.getVirtualItems();

  const loadMessages = useCallback(() => {
    d(store.loadRoomMessages()).then((count) => {
      if (count == null) {
        return;
      }

      if (count > 0) {
        const currentIndex = virtualizer.range?.startIndex || 0;
        scrollToIndex(currentIndex + count, "start");
      }
    });
  }, [virtualizer, scrollToIndex, d]);

  // 初期位置をチャットの末尾にする
  useEffect(() => {
    prevScrollTop.current = 0;

    if (initialized) {
      window.setTimeout(() => {
        if (virtualizer.options.count > 0) {
          scrollToIndex(virtualizer.options.count - 1, "end");
        }
        edge.current = true;
      }, 0);
    }
  }, [tab, initialized, edge, virtualizer, scrollToIndex]);

  // 新しいメッセージが追加された時に末尾へ移動する
  useEffect(() => {
    if (edge.current && messageIds.length > 0) {
      scrollToIndex(messageIds.length - 1, "end");
      edge.current = true;
    }
  }, [virtualizer, scrollToIndex, messageIds.length, edge]);

  // 古いメッセージの初回ロード
  useEffect(() => {
    if (messageIds.length < 20) {
      loadMessages();
    }
  }, [loadMessages, messageIds]);

  // 一定の場所までスクロールしたらメッセージをロードする
  const onScroll = useCallback(() => {
    if (parentRef.current == null) {
      return;
    }

    const isBackforward = prevScrollTop.current > parentRef.current.scrollTop;
    prevScrollTop.current = parentRef.current.scrollTop;
    if (isBackforward) {
      if (parentRef.current.scrollTop < 32) {
        loadMessages();
      }
    }

    // チャット末尾にいるかの更新
    edge.current =
      parentRef.current.scrollHeight -
        parentRef.current.clientHeight -
        parentRef.current.scrollTop <=
      200;
  }, [loadMessages, prevScrollTop, parentRef]);

  return (
    <Container disablePadding ref={parentRef} onScroll={onScroll}>
      {loading && <MessagesCircularProgress size={24} />}
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items.map((virtualRow) => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
            >
              <Message messageId={messageIds[virtualRow.index]} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled(List)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
`;

const MessagesCircularProgress = styled(CircularProgress)`
  margin-left: -16px;
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 1;
`;

export default memo(Messages);
